/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `M` - Male * `F` - Female * `NS` - Not Specified
 */
export type GenderEnum = 'M' | 'F' | 'NS';

export const GenderEnum = {
    M: 'M' as GenderEnum,
    F: 'F' as GenderEnum,
    Ns: 'NS' as GenderEnum
};

