/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `SELF_PICK_UP` - Self Pick Up * `DELIVERY` - Delivery
 */
export type DeliveryMethodEnum = 'SELF_PICK_UP' | 'DELIVERY';

export const DeliveryMethodEnum = {
    SelfPickUp: 'SELF_PICK_UP' as DeliveryMethodEnum,
    Delivery: 'DELIVERY' as DeliveryMethodEnum
};

