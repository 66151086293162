/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `SUPER_ADMIN` - Super Admin * `MASTER_DATA_ADMIN` - Master Data Admin * `SALE_ADMIN` - Sale Admin * `CALL_CENTER_ADMIN` - Call Center * `SHOP_OWNER` - Shop Owner * `SHOP_ADMIN` - Shop Admin * `SHOP_MANAGER` - Shop Manager * `VAN_SALE` - Van Sale * `UNKNOWN` - Unknown * `FMCG_MANAGER` - FMCG Manager * `FMCG_ADMIN` - FMCG Admin
 */
export type PositionEnum = 'SUPER_ADMIN' | 'MASTER_DATA_ADMIN' | 'SALE_ADMIN' | 'CALL_CENTER_ADMIN' | 'SHOP_OWNER' | 'SHOP_ADMIN' | 'SHOP_MANAGER' | 'VAN_SALE' | 'UNKNOWN' | 'FMCG_MANAGER' | 'FMCG_ADMIN';

export const PositionEnum = {
    SuperAdmin: 'SUPER_ADMIN' as PositionEnum,
    MasterDataAdmin: 'MASTER_DATA_ADMIN' as PositionEnum,
    SaleAdmin: 'SALE_ADMIN' as PositionEnum,
    CallCenterAdmin: 'CALL_CENTER_ADMIN' as PositionEnum,
    ShopOwner: 'SHOP_OWNER' as PositionEnum,
    ShopAdmin: 'SHOP_ADMIN' as PositionEnum,
    ShopManager: 'SHOP_MANAGER' as PositionEnum,
    VanSale: 'VAN_SALE' as PositionEnum,
    Unknown: 'UNKNOWN' as PositionEnum,
    FmcgManager: 'FMCG_MANAGER' as PositionEnum,
    FmcgAdmin: 'FMCG_ADMIN' as PositionEnum
};

