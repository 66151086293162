/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `KG` - กิโลกรัม * `G` - กรัม
 */
export type WeightUnitEnum = 'KG' | 'G';

export const WeightUnitEnum = {
    Kg: 'KG' as WeightUnitEnum,
    G: 'G' as WeightUnitEnum
};

