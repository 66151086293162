/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `WS` - Wholesale * `GT` - General Trade * `UK` - Unknown * `AD` - Admin * `FM` - FMCG
 */
export type RoleEnum = 'WS' | 'GT' | 'UK' | 'AD' | 'FM';

export const RoleEnum = {
    Ws: 'WS' as RoleEnum,
    Gt: 'GT' as RoleEnum,
    Uk: 'UK' as RoleEnum,
    Ad: 'AD' as RoleEnum,
    Fm: 'FM' as RoleEnum
};

