/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `FLAT_DISCOUNT` - Flat Discount * `PROGRESSIVE` - Progressive * `BUY_WITH_DISCOUNT` - Buy With Discount * `BOGO` - Bogo
 */
export type PromotionTypeEnum = 'FLAT_DISCOUNT' | 'PROGRESSIVE' | 'BUY_WITH_DISCOUNT' | 'BOGO';

export const PromotionTypeEnum = {
    FlatDiscount: 'FLAT_DISCOUNT' as PromotionTypeEnum,
    Progressive: 'PROGRESSIVE' as PromotionTypeEnum,
    BuyWithDiscount: 'BUY_WITH_DISCOUNT' as PromotionTypeEnum,
    Bogo: 'BOGO' as PromotionTypeEnum
};

