<div *ngIf="visible" [ngClass]="modalClasses">
  <div class="relative w-auto" [ngClass]="dialogClasses">
    <div
      class="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none"
      [ngClass]="contentClasses"
    >
      <!--header-->
      <div
        *ngIf="showHeader"
        class="flex items-start border-b border-solid border-blueGray-200 rounded-t"
        [ngClass]="showCloseHeader ? 'flex-col justify-start px-4 py-2' : 'justify-center p-4'"
      >
        @if (showCloseHeader) {
          <app-close-button-header (closeButtonClick)="close()" class="w-full font-bold"></app-close-button-header>
        }
        <h6 class="text-lg font-bold leading-normal">{{ header }}</h6>
      </div>

      <!-- body -->
      @if (showCloseContent) {
        <app-close-button-header (closeButtonClick)="close()" class="w-full pt-2 pl-4 pr-3"></app-close-button-header>
      }

      <div class="relative flex-auto" [ngClass]="hasDetail ? 'p-4' : 'px-4 py-6'">
        <ng-container *ngTemplateOutlet="contentRef!"></ng-container>
      </div>

      <!--footer-->
      <div
        *ngIf="showFooter"
        class="flex items-center justify-center p-4 border-t border-solid border-blueGray-200 rounded-b gap-2"
      >
        <app-button
          *ngIf="showCancel"
          class="w-full"
          [buttonType]="leftButtonType"
          [label]="leftButtonText"
          buttonSize="l-full"
          data-test-id="modal-cancel-button"
          (clickButton)="close()"
        ></app-button>
        <app-button
          *ngIf="showConfirm"
          class="w-full"
          [label]="rightButtonText"
          buttonType="primary"
          buttonSize="l-full"
          data-test-id="modal-confirm-button"
          (clickButton)="click()"
        ></app-button>
      </div>

      <ng-container *ngTemplateOutlet="footerRef!"></ng-container>
    </div>
  </div>
</div>
<div *ngIf="visible" class="opacity-70 fixed inset-0 z-40 bg-black top-0 w-full h-full"></div>
