/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { AdsBannerMasterGeneralTradeGet } from '../model/adsBannerMasterGeneralTradeGet';
// @ts-ignore
import { AdsBannerMasterList } from '../model/adsBannerMasterList';
// @ts-ignore
import { AdsBannerWholesaleForGeneralTradeGet } from '../model/adsBannerWholesaleForGeneralTradeGet';
// @ts-ignore
import { AdsBannerWholesaleForGeneralTradeList } from '../model/adsBannerWholesaleForGeneralTradeList';
// @ts-ignore
import { Cart } from '../model/cart';
// @ts-ignore
import { CartDeliveryMethodUpdateRequest } from '../model/cartDeliveryMethodUpdateRequest';
// @ts-ignore
import { CouponCount } from '../model/couponCount';
// @ts-ignore
import { CouponDetailCartOrder } from '../model/couponDetailCartOrder';
// @ts-ignore
import { GeneralTradeHome } from '../model/generalTradeHome';
// @ts-ignore
import { GeneralTradePost } from '../model/generalTradePost';
// @ts-ignore
import { GeneralTradePostRequest } from '../model/generalTradePostRequest';
// @ts-ignore
import { GeneralTradeProfileCheckBannerLicense } from '../model/generalTradeProfileCheckBannerLicense';
// @ts-ignore
import { GeneralTradeProfileGet } from '../model/generalTradeProfileGet';
// @ts-ignore
import { GeneralTradeProfilePatch } from '../model/generalTradeProfilePatch';
// @ts-ignore
import { GeneralTradeProfilePut } from '../model/generalTradeProfilePut';
// @ts-ignore
import { GeneralTradeProfilePutRequest } from '../model/generalTradeProfilePutRequest';
// @ts-ignore
import { GeneralTradeShopType } from '../model/generalTradeShopType';
// @ts-ignore
import { GeneralTradeStatus } from '../model/generalTradeStatus';
// @ts-ignore
import { MinimumProductPackageWithPromotion } from '../model/minimumProductPackageWithPromotion';
// @ts-ignore
import { OrderDetailGet } from '../model/orderDetailGet';
// @ts-ignore
import { PaginatedGeneralTradeCouponForCartListList } from '../model/paginatedGeneralTradeCouponForCartListList';
// @ts-ignore
import { PaginatedMinimumProductPackageWithPromotionList } from '../model/paginatedMinimumProductPackageWithPromotionList';
// @ts-ignore
import { PatchedCartItemBuyWithDiscountRequest } from '../model/patchedCartItemBuyWithDiscountRequest';
// @ts-ignore
import { PatchedCartItemDetailedRequest } from '../model/patchedCartItemDetailedRequest';
// @ts-ignore
import { PromotionPolymorphic } from '../model/promotionPolymorphic';
// @ts-ignore
import { RemoveCouponForCartRequest } from '../model/removeCouponForCartRequest';
// @ts-ignore
import { WholeSaleProfileBasic } from '../model/wholeSaleProfileBasic';
// @ts-ignore
import { WholeSaleProfileByCodeRequestRequest } from '../model/wholeSaleProfileByCodeRequestRequest';
// @ts-ignore
import { WishListID } from '../model/wishListID';
// @ts-ignore
import { WishListUpdateRequest } from '../model/wishListUpdateRequest';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface GeneralTradesBannersMasterProductPackagesListRequestParams {
    bannerId: string;
    brand?: number;
    /** The pagination cursor value. */
    cursor?: string;
    /** display_name */
    displayName?: string;
    hasPromotion?: boolean;
    isDefault?: boolean;
    isNotForSell?: boolean;
    isPin?: boolean;
    isPublic?: boolean;
    isTemporarilyUnavailable?: boolean;
    name?: string;
    notifyUpdateFromMaster?: boolean;
    /** Number of results to return per page. */
    pageSize?: number;
    primaryCategory?: string;
    primaryCategoryId?: string;
    product?: number;
    productGroup?: number;
    productGroupNull?: boolean;
    /** search */
    search?: string;
    secondaryCategory?: string;
    subCategory?: string;
}

export interface GeneralTradesBannersMasterRetrieveRequestParams {
    id: string;
}

export interface GeneralTradesBannersWholesaleProductPackagesListRequestParams {
    bannerId: string;
    brand?: number;
    /** The pagination cursor value. */
    cursor?: string;
    /** display_name */
    displayName?: string;
    hasPromotion?: boolean;
    isDefault?: boolean;
    isNotForSell?: boolean;
    isPin?: boolean;
    isPublic?: boolean;
    isTemporarilyUnavailable?: boolean;
    name?: string;
    notifyUpdateFromMaster?: boolean;
    /** Number of results to return per page. */
    pageSize?: number;
    primaryCategory?: string;
    primaryCategoryId?: string;
    product?: number;
    productGroup?: number;
    productGroupNull?: boolean;
    /** search */
    search?: string;
    secondaryCategory?: string;
    subCategory?: string;
}

export interface GeneralTradesBannersWholesaleRetrieveRequestParams {
    id: string;
}

export interface GeneralTradesCartBuyWithDiscountItemPartialUpdateRequestParams {
    patchedCartItemBuyWithDiscountRequest?: PatchedCartItemBuyWithDiscountRequest;
}

export interface GeneralTradesCartCouponsListRequestParams {
    /** The pagination cursor value. */
    cursor?: string;
    /** Number of results to return per page. */
    pageSize?: number;
}

export interface GeneralTradesCartCouponsProductPackagesListRequestParams {
    couponId: number;
    /** The pagination cursor value. */
    cursor?: string;
    /** Number of results to return per page. */
    pageSize?: number;
}

export interface GeneralTradesCartCouponsProductPackagesRetrieveRequestParams {
    couponId: number;
    id: string;
}

export interface GeneralTradesCartCouponsRemoveCreateRequestParams {
    removeCouponForCartRequest: RemoveCouponForCartRequest;
}

export interface GeneralTradesCartCouponsRetrieveRequestParams {
    id: string;
}

export interface GeneralTradesCartCouponsUseCreateRequestParams {
    id: string;
}

export interface GeneralTradesCartDeliveryMethodUpdateRequestParams {
    cartDeliveryMethodUpdateRequest: CartDeliveryMethodUpdateRequest;
}

export interface GeneralTradesCartPartialUpdateRequestParams {
    patchedCartItemDetailedRequest?: PatchedCartItemDetailedRequest;
}

export interface GeneralTradesCheckBannerLicenseRetrieveRequestParams {
    /** A unique integer value identifying this general trade profile. */
    id: number;
}

export interface GeneralTradesConnectWithWholesaleCreateRequestParams {
    /** A unique integer value identifying this general trade profile. */
    id: number;
    wholeSaleProfileByCodeRequestRequest: WholeSaleProfileByCodeRequestRequest;
}

export interface GeneralTradesCreateRequestParams {
    generalTradePostRequest: GeneralTradePostRequest;
}

export interface GeneralTradesPartialUpdateRequestParams {
    /** A unique integer value identifying this general trade profile. */
    id: number;
    name?: string;
    phone?: string;
    picture?: Blob;
    latitude?: string;
    longitude?: string;
    ageOver18?: boolean;
    hasAlcoholLicense?: boolean;
    hasTobaccoLicense?: boolean;
    alcoholLicense?: Blob | null;
    tobaccoLicense?: Blob | null;
}

export interface GeneralTradesRetrieveRequestParams {
    /** A unique integer value identifying this general trade profile. */
    id: number;
}

export interface GeneralTradesUpdateRequestParams {
    /** A unique integer value identifying this general trade profile. */
    id: number;
    generalTradeProfilePutRequest: GeneralTradeProfilePutRequest;
}

export interface GeneralTradesUpdateGeneralTradeLicenseDataUpdateRequestParams {
    /** A unique integer value identifying this general trade profile. */
    id: number;
    picture: Blob;
    latitude: string;
    longitude: string;
    ageOver18?: boolean;
    hasAlcoholLicense?: boolean;
    hasTobaccoLicense?: boolean;
    alcoholLicense?: Blob;
    tobaccoLicense?: Blob;
}

export interface GeneralTradesWholesaleProductsListRequestParams {
    brand?: number;
    /** The pagination cursor value. */
    cursor?: string;
    /** display_name */
    displayName?: string;
    hasPromotion?: boolean;
    isDefault?: boolean;
    isNotForSell?: boolean;
    isPin?: boolean;
    isPublic?: boolean;
    isTemporarilyUnavailable?: boolean;
    name?: string;
    notifyUpdateFromMaster?: boolean;
    /** Number of results to return per page. */
    pageSize?: number;
    primaryCategory?: string;
    primaryCategoryId?: string;
    product?: number;
    productGroup?: number;
    productGroupNull?: boolean;
    /** search */
    search?: string;
    secondaryCategory?: string;
    subCategory?: string;
}

export interface GeneralTradesWholesaleProductsPromotionDetailRetrieveRequestParams {
    /** A unique integer value identifying this product package. */
    id: number;
}

export interface GeneralTradesWishlistListRequestParams {
    brand?: number;
    /** The pagination cursor value. */
    cursor?: string;
    /** display_name */
    displayName?: string;
    hasPromotion?: boolean;
    isDefault?: boolean;
    isNotForSell?: boolean;
    isPin?: boolean;
    isPublic?: boolean;
    isTemporarilyUnavailable?: boolean;
    name?: string;
    notifyUpdateFromMaster?: boolean;
    /** Number of results to return per page. */
    pageSize?: number;
    primaryCategory?: string;
    primaryCategoryId?: string;
    product?: number;
    productGroup?: number;
    productGroupNull?: boolean;
    /** A search term. */
    search?: string;
    secondaryCategory?: string;
    subCategory?: string;
}

export interface GeneralTradesWishlistSetWishlistCreateRequestParams {
    wishListUpdateRequest: WishListUpdateRequest;
}


@Injectable({
  providedIn: 'root'
})
export class GeneralTradesService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generalTradesBannersMasterList(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<AdsBannerMasterList>>;
    public generalTradesBannersMasterList(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<AdsBannerMasterList>>>;
    public generalTradesBannersMasterList(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<AdsBannerMasterList>>>;
    public generalTradesBannersMasterList(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/general-trades/banners/master/`;
        return this.httpClient.request<Array<AdsBannerMasterList>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generalTradesBannersMasterProductPackagesList(requestParameters: GeneralTradesBannersMasterProductPackagesListRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PaginatedMinimumProductPackageWithPromotionList>;
    public generalTradesBannersMasterProductPackagesList(requestParameters: GeneralTradesBannersMasterProductPackagesListRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PaginatedMinimumProductPackageWithPromotionList>>;
    public generalTradesBannersMasterProductPackagesList(requestParameters: GeneralTradesBannersMasterProductPackagesListRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PaginatedMinimumProductPackageWithPromotionList>>;
    public generalTradesBannersMasterProductPackagesList(requestParameters: GeneralTradesBannersMasterProductPackagesListRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const bannerId = requestParameters.bannerId;
        if (bannerId === null || bannerId === undefined) {
            throw new Error('Required parameter bannerId was null or undefined when calling generalTradesBannersMasterProductPackagesList.');
        }
        const brand = requestParameters.brand;
        const cursor = requestParameters.cursor;
        const displayName = requestParameters.displayName;
        const hasPromotion = requestParameters.hasPromotion;
        const isDefault = requestParameters.isDefault;
        const isNotForSell = requestParameters.isNotForSell;
        const isPin = requestParameters.isPin;
        const isPublic = requestParameters.isPublic;
        const isTemporarilyUnavailable = requestParameters.isTemporarilyUnavailable;
        const name = requestParameters.name;
        const notifyUpdateFromMaster = requestParameters.notifyUpdateFromMaster;
        const pageSize = requestParameters.pageSize;
        const primaryCategory = requestParameters.primaryCategory;
        const primaryCategoryId = requestParameters.primaryCategoryId;
        const product = requestParameters.product;
        const productGroup = requestParameters.productGroup;
        const productGroupNull = requestParameters.productGroupNull;
        const search = requestParameters.search;
        const secondaryCategory = requestParameters.secondaryCategory;
        const subCategory = requestParameters.subCategory;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (brand !== undefined && brand !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>brand, 'brand');
        }
        if (cursor !== undefined && cursor !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>cursor, 'cursor');
        }
        if (displayName !== undefined && displayName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>displayName, 'display_name');
        }
        if (hasPromotion !== undefined && hasPromotion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>hasPromotion, 'has_promotion');
        }
        if (isDefault !== undefined && isDefault !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isDefault, 'is_default');
        }
        if (isNotForSell !== undefined && isNotForSell !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isNotForSell, 'is_not_for_sell');
        }
        if (isPin !== undefined && isPin !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isPin, 'is_pin');
        }
        if (isPublic !== undefined && isPublic !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isPublic, 'is_public');
        }
        if (isTemporarilyUnavailable !== undefined && isTemporarilyUnavailable !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isTemporarilyUnavailable, 'is_temporarily_unavailable');
        }
        if (name !== undefined && name !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>name, 'name');
        }
        if (notifyUpdateFromMaster !== undefined && notifyUpdateFromMaster !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>notifyUpdateFromMaster, 'notify_update_from_master');
        }
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'page_size');
        }
        if (primaryCategory !== undefined && primaryCategory !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>primaryCategory, 'primary_category');
        }
        if (primaryCategoryId !== undefined && primaryCategoryId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>primaryCategoryId, 'primary_category_id');
        }
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'product');
        }
        if (productGroup !== undefined && productGroup !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>productGroup, 'product_group');
        }
        if (productGroupNull !== undefined && productGroupNull !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>productGroupNull, 'product_group_null');
        }
        if (search !== undefined && search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>search, 'search');
        }
        if (secondaryCategory !== undefined && secondaryCategory !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>secondaryCategory, 'secondary_category');
        }
        if (subCategory !== undefined && subCategory !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>subCategory, 'sub_category');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/general-trades/banners/master/${this.configuration.encodeParam({name: "bannerId", value: bannerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/product-packages/`;
        return this.httpClient.request<PaginatedMinimumProductPackageWithPromotionList>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generalTradesBannersMasterRetrieve(requestParameters: GeneralTradesBannersMasterRetrieveRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<AdsBannerMasterGeneralTradeGet>;
    public generalTradesBannersMasterRetrieve(requestParameters: GeneralTradesBannersMasterRetrieveRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<AdsBannerMasterGeneralTradeGet>>;
    public generalTradesBannersMasterRetrieve(requestParameters: GeneralTradesBannersMasterRetrieveRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<AdsBannerMasterGeneralTradeGet>>;
    public generalTradesBannersMasterRetrieve(requestParameters: GeneralTradesBannersMasterRetrieveRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling generalTradesBannersMasterRetrieve.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/general-trades/banners/master/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/`;
        return this.httpClient.request<AdsBannerMasterGeneralTradeGet>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generalTradesBannersWholesaleList(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<AdsBannerWholesaleForGeneralTradeList>>;
    public generalTradesBannersWholesaleList(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<AdsBannerWholesaleForGeneralTradeList>>>;
    public generalTradesBannersWholesaleList(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<AdsBannerWholesaleForGeneralTradeList>>>;
    public generalTradesBannersWholesaleList(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/general-trades/banners/wholesale/`;
        return this.httpClient.request<Array<AdsBannerWholesaleForGeneralTradeList>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generalTradesBannersWholesaleProductPackagesList(requestParameters: GeneralTradesBannersWholesaleProductPackagesListRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PaginatedMinimumProductPackageWithPromotionList>;
    public generalTradesBannersWholesaleProductPackagesList(requestParameters: GeneralTradesBannersWholesaleProductPackagesListRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PaginatedMinimumProductPackageWithPromotionList>>;
    public generalTradesBannersWholesaleProductPackagesList(requestParameters: GeneralTradesBannersWholesaleProductPackagesListRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PaginatedMinimumProductPackageWithPromotionList>>;
    public generalTradesBannersWholesaleProductPackagesList(requestParameters: GeneralTradesBannersWholesaleProductPackagesListRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const bannerId = requestParameters.bannerId;
        if (bannerId === null || bannerId === undefined) {
            throw new Error('Required parameter bannerId was null or undefined when calling generalTradesBannersWholesaleProductPackagesList.');
        }
        const brand = requestParameters.brand;
        const cursor = requestParameters.cursor;
        const displayName = requestParameters.displayName;
        const hasPromotion = requestParameters.hasPromotion;
        const isDefault = requestParameters.isDefault;
        const isNotForSell = requestParameters.isNotForSell;
        const isPin = requestParameters.isPin;
        const isPublic = requestParameters.isPublic;
        const isTemporarilyUnavailable = requestParameters.isTemporarilyUnavailable;
        const name = requestParameters.name;
        const notifyUpdateFromMaster = requestParameters.notifyUpdateFromMaster;
        const pageSize = requestParameters.pageSize;
        const primaryCategory = requestParameters.primaryCategory;
        const primaryCategoryId = requestParameters.primaryCategoryId;
        const product = requestParameters.product;
        const productGroup = requestParameters.productGroup;
        const productGroupNull = requestParameters.productGroupNull;
        const search = requestParameters.search;
        const secondaryCategory = requestParameters.secondaryCategory;
        const subCategory = requestParameters.subCategory;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (brand !== undefined && brand !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>brand, 'brand');
        }
        if (cursor !== undefined && cursor !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>cursor, 'cursor');
        }
        if (displayName !== undefined && displayName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>displayName, 'display_name');
        }
        if (hasPromotion !== undefined && hasPromotion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>hasPromotion, 'has_promotion');
        }
        if (isDefault !== undefined && isDefault !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isDefault, 'is_default');
        }
        if (isNotForSell !== undefined && isNotForSell !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isNotForSell, 'is_not_for_sell');
        }
        if (isPin !== undefined && isPin !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isPin, 'is_pin');
        }
        if (isPublic !== undefined && isPublic !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isPublic, 'is_public');
        }
        if (isTemporarilyUnavailable !== undefined && isTemporarilyUnavailable !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isTemporarilyUnavailable, 'is_temporarily_unavailable');
        }
        if (name !== undefined && name !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>name, 'name');
        }
        if (notifyUpdateFromMaster !== undefined && notifyUpdateFromMaster !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>notifyUpdateFromMaster, 'notify_update_from_master');
        }
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'page_size');
        }
        if (primaryCategory !== undefined && primaryCategory !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>primaryCategory, 'primary_category');
        }
        if (primaryCategoryId !== undefined && primaryCategoryId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>primaryCategoryId, 'primary_category_id');
        }
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'product');
        }
        if (productGroup !== undefined && productGroup !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>productGroup, 'product_group');
        }
        if (productGroupNull !== undefined && productGroupNull !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>productGroupNull, 'product_group_null');
        }
        if (search !== undefined && search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>search, 'search');
        }
        if (secondaryCategory !== undefined && secondaryCategory !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>secondaryCategory, 'secondary_category');
        }
        if (subCategory !== undefined && subCategory !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>subCategory, 'sub_category');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/general-trades/banners/wholesale/${this.configuration.encodeParam({name: "bannerId", value: bannerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/product-packages/`;
        return this.httpClient.request<PaginatedMinimumProductPackageWithPromotionList>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generalTradesBannersWholesaleRetrieve(requestParameters: GeneralTradesBannersWholesaleRetrieveRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<AdsBannerWholesaleForGeneralTradeGet>;
    public generalTradesBannersWholesaleRetrieve(requestParameters: GeneralTradesBannersWholesaleRetrieveRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<AdsBannerWholesaleForGeneralTradeGet>>;
    public generalTradesBannersWholesaleRetrieve(requestParameters: GeneralTradesBannersWholesaleRetrieveRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<AdsBannerWholesaleForGeneralTradeGet>>;
    public generalTradesBannersWholesaleRetrieve(requestParameters: GeneralTradesBannersWholesaleRetrieveRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling generalTradesBannersWholesaleRetrieve.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/general-trades/banners/wholesale/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/`;
        return this.httpClient.request<AdsBannerWholesaleForGeneralTradeGet>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generalTradesCartBuyWithDiscountItemPartialUpdate(requestParameters: GeneralTradesCartBuyWithDiscountItemPartialUpdateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Cart>;
    public generalTradesCartBuyWithDiscountItemPartialUpdate(requestParameters: GeneralTradesCartBuyWithDiscountItemPartialUpdateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Cart>>;
    public generalTradesCartBuyWithDiscountItemPartialUpdate(requestParameters: GeneralTradesCartBuyWithDiscountItemPartialUpdateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Cart>>;
    public generalTradesCartBuyWithDiscountItemPartialUpdate(requestParameters: GeneralTradesCartBuyWithDiscountItemPartialUpdateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const patchedCartItemBuyWithDiscountRequest = requestParameters.patchedCartItemBuyWithDiscountRequest;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'multipart/form-data'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/general-trades/cart/buy-with-discount-item`;
        return this.httpClient.request<Cart>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: patchedCartItemBuyWithDiscountRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generalTradesCartCouponsList(requestParameters: GeneralTradesCartCouponsListRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PaginatedGeneralTradeCouponForCartListList>;
    public generalTradesCartCouponsList(requestParameters: GeneralTradesCartCouponsListRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PaginatedGeneralTradeCouponForCartListList>>;
    public generalTradesCartCouponsList(requestParameters: GeneralTradesCartCouponsListRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PaginatedGeneralTradeCouponForCartListList>>;
    public generalTradesCartCouponsList(requestParameters: GeneralTradesCartCouponsListRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const cursor = requestParameters.cursor;
        const pageSize = requestParameters.pageSize;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (cursor !== undefined && cursor !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>cursor, 'cursor');
        }
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'page_size');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/general-trades/cart/coupons/`;
        return this.httpClient.request<PaginatedGeneralTradeCouponForCartListList>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generalTradesCartCouponsProductPackagesList(requestParameters: GeneralTradesCartCouponsProductPackagesListRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PaginatedMinimumProductPackageWithPromotionList>;
    public generalTradesCartCouponsProductPackagesList(requestParameters: GeneralTradesCartCouponsProductPackagesListRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PaginatedMinimumProductPackageWithPromotionList>>;
    public generalTradesCartCouponsProductPackagesList(requestParameters: GeneralTradesCartCouponsProductPackagesListRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PaginatedMinimumProductPackageWithPromotionList>>;
    public generalTradesCartCouponsProductPackagesList(requestParameters: GeneralTradesCartCouponsProductPackagesListRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const couponId = requestParameters.couponId;
        if (couponId === null || couponId === undefined) {
            throw new Error('Required parameter couponId was null or undefined when calling generalTradesCartCouponsProductPackagesList.');
        }
        const cursor = requestParameters.cursor;
        const pageSize = requestParameters.pageSize;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (cursor !== undefined && cursor !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>cursor, 'cursor');
        }
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'page_size');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/general-trades/cart/coupons/${this.configuration.encodeParam({name: "couponId", value: couponId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/product-packages/`;
        return this.httpClient.request<PaginatedMinimumProductPackageWithPromotionList>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generalTradesCartCouponsProductPackagesRetrieve(requestParameters: GeneralTradesCartCouponsProductPackagesRetrieveRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<MinimumProductPackageWithPromotion>;
    public generalTradesCartCouponsProductPackagesRetrieve(requestParameters: GeneralTradesCartCouponsProductPackagesRetrieveRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<MinimumProductPackageWithPromotion>>;
    public generalTradesCartCouponsProductPackagesRetrieve(requestParameters: GeneralTradesCartCouponsProductPackagesRetrieveRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<MinimumProductPackageWithPromotion>>;
    public generalTradesCartCouponsProductPackagesRetrieve(requestParameters: GeneralTradesCartCouponsProductPackagesRetrieveRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const couponId = requestParameters.couponId;
        if (couponId === null || couponId === undefined) {
            throw new Error('Required parameter couponId was null or undefined when calling generalTradesCartCouponsProductPackagesRetrieve.');
        }
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling generalTradesCartCouponsProductPackagesRetrieve.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/general-trades/cart/coupons/${this.configuration.encodeParam({name: "couponId", value: couponId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/product-packages/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/`;
        return this.httpClient.request<MinimumProductPackageWithPromotion>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generalTradesCartCouponsRemoveCreate(requestParameters: GeneralTradesCartCouponsRemoveCreateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<CouponDetailCartOrder>;
    public generalTradesCartCouponsRemoveCreate(requestParameters: GeneralTradesCartCouponsRemoveCreateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<CouponDetailCartOrder>>;
    public generalTradesCartCouponsRemoveCreate(requestParameters: GeneralTradesCartCouponsRemoveCreateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<CouponDetailCartOrder>>;
    public generalTradesCartCouponsRemoveCreate(requestParameters: GeneralTradesCartCouponsRemoveCreateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const removeCouponForCartRequest = requestParameters.removeCouponForCartRequest;
        if (removeCouponForCartRequest === null || removeCouponForCartRequest === undefined) {
            throw new Error('Required parameter removeCouponForCartRequest was null or undefined when calling generalTradesCartCouponsRemoveCreate.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'multipart/form-data'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/general-trades/cart/coupons/remove/`;
        return this.httpClient.request<CouponDetailCartOrder>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: removeCouponForCartRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generalTradesCartCouponsRetrieve(requestParameters: GeneralTradesCartCouponsRetrieveRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<CouponDetailCartOrder>;
    public generalTradesCartCouponsRetrieve(requestParameters: GeneralTradesCartCouponsRetrieveRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<CouponDetailCartOrder>>;
    public generalTradesCartCouponsRetrieve(requestParameters: GeneralTradesCartCouponsRetrieveRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<CouponDetailCartOrder>>;
    public generalTradesCartCouponsRetrieve(requestParameters: GeneralTradesCartCouponsRetrieveRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling generalTradesCartCouponsRetrieve.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/general-trades/cart/coupons/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/`;
        return this.httpClient.request<CouponDetailCartOrder>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generalTradesCartCouponsUseCreate(requestParameters: GeneralTradesCartCouponsUseCreateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<CouponDetailCartOrder>;
    public generalTradesCartCouponsUseCreate(requestParameters: GeneralTradesCartCouponsUseCreateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<CouponDetailCartOrder>>;
    public generalTradesCartCouponsUseCreate(requestParameters: GeneralTradesCartCouponsUseCreateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<CouponDetailCartOrder>>;
    public generalTradesCartCouponsUseCreate(requestParameters: GeneralTradesCartCouponsUseCreateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling generalTradesCartCouponsUseCreate.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/general-trades/cart/coupons/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/use/`;
        return this.httpClient.request<CouponDetailCartOrder>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * return number of available coupon for cart
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generalTradesCartCouponsValidCountRetrieve(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<CouponCount>;
    public generalTradesCartCouponsValidCountRetrieve(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<CouponCount>>;
    public generalTradesCartCouponsValidCountRetrieve(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<CouponCount>>;
    public generalTradesCartCouponsValidCountRetrieve(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/general-trades/cart/coupons/valid-count/`;
        return this.httpClient.request<CouponCount>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generalTradesCartDeliveryMethodUpdate(requestParameters: GeneralTradesCartDeliveryMethodUpdateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Cart>;
    public generalTradesCartDeliveryMethodUpdate(requestParameters: GeneralTradesCartDeliveryMethodUpdateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Cart>>;
    public generalTradesCartDeliveryMethodUpdate(requestParameters: GeneralTradesCartDeliveryMethodUpdateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Cart>>;
    public generalTradesCartDeliveryMethodUpdate(requestParameters: GeneralTradesCartDeliveryMethodUpdateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const cartDeliveryMethodUpdateRequest = requestParameters.cartDeliveryMethodUpdateRequest;
        if (cartDeliveryMethodUpdateRequest === null || cartDeliveryMethodUpdateRequest === undefined) {
            throw new Error('Required parameter cartDeliveryMethodUpdateRequest was null or undefined when calling generalTradesCartDeliveryMethodUpdate.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'multipart/form-data'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/general-trades/cart/delivery-method`;
        return this.httpClient.request<Cart>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: cartDeliveryMethodUpdateRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generalTradesCartDestroy(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Cart>;
    public generalTradesCartDestroy(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Cart>>;
    public generalTradesCartDestroy(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Cart>>;
    public generalTradesCartDestroy(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/general-trades/cart`;
        return this.httpClient.request<Cart>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generalTradesCartPartialUpdate(requestParameters: GeneralTradesCartPartialUpdateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Cart>;
    public generalTradesCartPartialUpdate(requestParameters: GeneralTradesCartPartialUpdateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Cart>>;
    public generalTradesCartPartialUpdate(requestParameters: GeneralTradesCartPartialUpdateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Cart>>;
    public generalTradesCartPartialUpdate(requestParameters: GeneralTradesCartPartialUpdateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const patchedCartItemDetailedRequest = requestParameters.patchedCartItemDetailedRequest;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'multipart/form-data'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/general-trades/cart`;
        return this.httpClient.request<Cart>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: patchedCartItemDetailedRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generalTradesCartRemoveOutOfStockDestroy(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public generalTradesCartRemoveOutOfStockDestroy(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public generalTradesCartRemoveOutOfStockDestroy(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public generalTradesCartRemoveOutOfStockDestroy(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/general-trades/cart/remove-out-of-stock`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generalTradesCartRetrieve(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Cart>;
    public generalTradesCartRetrieve(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Cart>>;
    public generalTradesCartRetrieve(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Cart>>;
    public generalTradesCartRetrieve(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/general-trades/cart`;
        return this.httpClient.request<Cart>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generalTradesCheckBannerLicenseRetrieve(requestParameters: GeneralTradesCheckBannerLicenseRetrieveRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<GeneralTradeProfileCheckBannerLicense>;
    public generalTradesCheckBannerLicenseRetrieve(requestParameters: GeneralTradesCheckBannerLicenseRetrieveRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<GeneralTradeProfileCheckBannerLicense>>;
    public generalTradesCheckBannerLicenseRetrieve(requestParameters: GeneralTradesCheckBannerLicenseRetrieveRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<GeneralTradeProfileCheckBannerLicense>>;
    public generalTradesCheckBannerLicenseRetrieve(requestParameters: GeneralTradesCheckBannerLicenseRetrieveRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling generalTradesCheckBannerLicenseRetrieve.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/general-trades/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/check-banner-license/`;
        return this.httpClient.request<GeneralTradeProfileCheckBannerLicense>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generalTradesConnectWithWholesaleCreate(requestParameters: GeneralTradesConnectWithWholesaleCreateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<WholeSaleProfileBasic>;
    public generalTradesConnectWithWholesaleCreate(requestParameters: GeneralTradesConnectWithWholesaleCreateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<WholeSaleProfileBasic>>;
    public generalTradesConnectWithWholesaleCreate(requestParameters: GeneralTradesConnectWithWholesaleCreateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<WholeSaleProfileBasic>>;
    public generalTradesConnectWithWholesaleCreate(requestParameters: GeneralTradesConnectWithWholesaleCreateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling generalTradesConnectWithWholesaleCreate.');
        }
        const wholeSaleProfileByCodeRequestRequest = requestParameters.wholeSaleProfileByCodeRequestRequest;
        if (wholeSaleProfileByCodeRequestRequest === null || wholeSaleProfileByCodeRequestRequest === undefined) {
            throw new Error('Required parameter wholeSaleProfileByCodeRequestRequest was null or undefined when calling generalTradesConnectWithWholesaleCreate.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/general-trades/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/connect-with-wholesale/`;
        return this.httpClient.request<WholeSaleProfileBasic>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: wholeSaleProfileByCodeRequestRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generalTradesCreate(requestParameters: GeneralTradesCreateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<GeneralTradePost>;
    public generalTradesCreate(requestParameters: GeneralTradesCreateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<GeneralTradePost>>;
    public generalTradesCreate(requestParameters: GeneralTradesCreateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<GeneralTradePost>>;
    public generalTradesCreate(requestParameters: GeneralTradesCreateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const generalTradePostRequest = requestParameters.generalTradePostRequest;
        if (generalTradePostRequest === null || generalTradePostRequest === undefined) {
            throw new Error('Required parameter generalTradePostRequest was null or undefined when calling generalTradesCreate.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'multipart/form-data'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/general-trades/`;
        return this.httpClient.request<GeneralTradePost>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: generalTradePostRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generalTradesHomeRetrieve(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<GeneralTradeHome>;
    public generalTradesHomeRetrieve(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<GeneralTradeHome>>;
    public generalTradesHomeRetrieve(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<GeneralTradeHome>>;
    public generalTradesHomeRetrieve(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/general-trades/home`;
        return this.httpClient.request<GeneralTradeHome>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generalTradesList(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<GeneralTradeProfileGet>>;
    public generalTradesList(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<GeneralTradeProfileGet>>>;
    public generalTradesList(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<GeneralTradeProfileGet>>>;
    public generalTradesList(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/general-trades/`;
        return this.httpClient.request<Array<GeneralTradeProfileGet>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generalTradesOrderCreate(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<OrderDetailGet>;
    public generalTradesOrderCreate(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<OrderDetailGet>>;
    public generalTradesOrderCreate(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<OrderDetailGet>>;
    public generalTradesOrderCreate(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/general-trades/order`;
        return this.httpClient.request<OrderDetailGet>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generalTradesPartialUpdate(requestParameters: GeneralTradesPartialUpdateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<GeneralTradeProfilePatch>;
    public generalTradesPartialUpdate(requestParameters: GeneralTradesPartialUpdateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<GeneralTradeProfilePatch>>;
    public generalTradesPartialUpdate(requestParameters: GeneralTradesPartialUpdateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<GeneralTradeProfilePatch>>;
    public generalTradesPartialUpdate(requestParameters: GeneralTradesPartialUpdateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling generalTradesPartialUpdate.');
        }
        const name = requestParameters.name;
        const phone = requestParameters.phone;
        const picture = requestParameters.picture;
        const latitude = requestParameters.latitude;
        const longitude = requestParameters.longitude;
        const ageOver18 = requestParameters.ageOver18;
        const hasAlcoholLicense = requestParameters.hasAlcoholLicense;
        const hasTobaccoLicense = requestParameters.hasTobaccoLicense;
        const alcoholLicense = requestParameters.alcoholLicense;
        const tobaccoLicense = requestParameters.tobaccoLicense;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (name !== undefined) {
            localVarFormParams = localVarFormParams.append('name', <any>name) as any || localVarFormParams;
        }
        if (phone !== undefined) {
            localVarFormParams = localVarFormParams.append('phone', <any>phone) as any || localVarFormParams;
        }
        if (picture !== undefined) {
            localVarFormParams = localVarFormParams.append('picture', <any>picture) as any || localVarFormParams;
        }
        if (latitude !== undefined) {
            localVarFormParams = localVarFormParams.append('latitude', <any>latitude) as any || localVarFormParams;
        }
        if (longitude !== undefined) {
            localVarFormParams = localVarFormParams.append('longitude', <any>longitude) as any || localVarFormParams;
        }
        if (ageOver18 !== undefined) {
            localVarFormParams = localVarFormParams.append('ageOver18', <any>ageOver18) as any || localVarFormParams;
        }
        if (hasAlcoholLicense !== undefined) {
            localVarFormParams = localVarFormParams.append('hasAlcoholLicense', <any>hasAlcoholLicense) as any || localVarFormParams;
        }
        if (hasTobaccoLicense !== undefined) {
            localVarFormParams = localVarFormParams.append('hasTobaccoLicense', <any>hasTobaccoLicense) as any || localVarFormParams;
        }
        if (alcoholLicense !== undefined) {
            localVarFormParams = localVarFormParams.append('alcoholLicense', <any>alcoholLicense) as any || localVarFormParams;
        }
        if (tobaccoLicense !== undefined) {
            localVarFormParams = localVarFormParams.append('tobaccoLicense', <any>tobaccoLicense) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/general-trades/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/`;
        return this.httpClient.request<GeneralTradeProfilePatch>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generalTradesRetrieve(requestParameters: GeneralTradesRetrieveRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<GeneralTradeProfileGet>;
    public generalTradesRetrieve(requestParameters: GeneralTradesRetrieveRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<GeneralTradeProfileGet>>;
    public generalTradesRetrieve(requestParameters: GeneralTradesRetrieveRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<GeneralTradeProfileGet>>;
    public generalTradesRetrieve(requestParameters: GeneralTradesRetrieveRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling generalTradesRetrieve.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/general-trades/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/`;
        return this.httpClient.request<GeneralTradeProfileGet>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generalTradesShopTypesList(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<GeneralTradeShopType>>;
    public generalTradesShopTypesList(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<GeneralTradeShopType>>>;
    public generalTradesShopTypesList(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<GeneralTradeShopType>>>;
    public generalTradesShopTypesList(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/general-trades/shop-types/`;
        return this.httpClient.request<Array<GeneralTradeShopType>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generalTradesStatusRetrieve(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<GeneralTradeStatus>;
    public generalTradesStatusRetrieve(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<GeneralTradeStatus>>;
    public generalTradesStatusRetrieve(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<GeneralTradeStatus>>;
    public generalTradesStatusRetrieve(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/general-trades/status/`;
        return this.httpClient.request<GeneralTradeStatus>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generalTradesUpdate(requestParameters: GeneralTradesUpdateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<GeneralTradeProfilePut>;
    public generalTradesUpdate(requestParameters: GeneralTradesUpdateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<GeneralTradeProfilePut>>;
    public generalTradesUpdate(requestParameters: GeneralTradesUpdateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<GeneralTradeProfilePut>>;
    public generalTradesUpdate(requestParameters: GeneralTradesUpdateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling generalTradesUpdate.');
        }
        const generalTradeProfilePutRequest = requestParameters.generalTradeProfilePutRequest;
        if (generalTradeProfilePutRequest === null || generalTradeProfilePutRequest === undefined) {
            throw new Error('Required parameter generalTradeProfilePutRequest was null or undefined when calling generalTradesUpdate.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'multipart/form-data'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/general-trades/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/`;
        return this.httpClient.request<GeneralTradeProfilePut>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: generalTradeProfilePutRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generalTradesUpdateGeneralTradeLicenseDataUpdate(requestParameters: GeneralTradesUpdateGeneralTradeLicenseDataUpdateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public generalTradesUpdateGeneralTradeLicenseDataUpdate(requestParameters: GeneralTradesUpdateGeneralTradeLicenseDataUpdateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public generalTradesUpdateGeneralTradeLicenseDataUpdate(requestParameters: GeneralTradesUpdateGeneralTradeLicenseDataUpdateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public generalTradesUpdateGeneralTradeLicenseDataUpdate(requestParameters: GeneralTradesUpdateGeneralTradeLicenseDataUpdateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling generalTradesUpdateGeneralTradeLicenseDataUpdate.');
        }
        const picture = requestParameters.picture;
        if (picture === null || picture === undefined) {
            throw new Error('Required parameter picture was null or undefined when calling generalTradesUpdateGeneralTradeLicenseDataUpdate.');
        }
        const latitude = requestParameters.latitude;
        if (latitude === null || latitude === undefined) {
            throw new Error('Required parameter latitude was null or undefined when calling generalTradesUpdateGeneralTradeLicenseDataUpdate.');
        }
        const longitude = requestParameters.longitude;
        if (longitude === null || longitude === undefined) {
            throw new Error('Required parameter longitude was null or undefined when calling generalTradesUpdateGeneralTradeLicenseDataUpdate.');
        }
        const ageOver18 = requestParameters.ageOver18;
        const hasAlcoholLicense = requestParameters.hasAlcoholLicense;
        const hasTobaccoLicense = requestParameters.hasTobaccoLicense;
        const alcoholLicense = requestParameters.alcoholLicense;
        const tobaccoLicense = requestParameters.tobaccoLicense;

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (picture !== undefined) {
            localVarFormParams = localVarFormParams.append('picture', <any>picture) as any || localVarFormParams;
        }
        if (latitude !== undefined) {
            localVarFormParams = localVarFormParams.append('latitude', <any>latitude) as any || localVarFormParams;
        }
        if (longitude !== undefined) {
            localVarFormParams = localVarFormParams.append('longitude', <any>longitude) as any || localVarFormParams;
        }
        if (ageOver18 !== undefined) {
            localVarFormParams = localVarFormParams.append('ageOver18', <any>ageOver18) as any || localVarFormParams;
        }
        if (hasAlcoholLicense !== undefined) {
            localVarFormParams = localVarFormParams.append('hasAlcoholLicense', <any>hasAlcoholLicense) as any || localVarFormParams;
        }
        if (hasTobaccoLicense !== undefined) {
            localVarFormParams = localVarFormParams.append('hasTobaccoLicense', <any>hasTobaccoLicense) as any || localVarFormParams;
        }
        if (alcoholLicense !== undefined) {
            localVarFormParams = localVarFormParams.append('alcoholLicense', <any>alcoholLicense) as any || localVarFormParams;
        }
        if (tobaccoLicense !== undefined) {
            localVarFormParams = localVarFormParams.append('tobaccoLicense', <any>tobaccoLicense) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/general-trades/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/update-general-trade-license-data/`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generalTradesWholesaleProductsList(requestParameters: GeneralTradesWholesaleProductsListRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PaginatedMinimumProductPackageWithPromotionList>;
    public generalTradesWholesaleProductsList(requestParameters: GeneralTradesWholesaleProductsListRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PaginatedMinimumProductPackageWithPromotionList>>;
    public generalTradesWholesaleProductsList(requestParameters: GeneralTradesWholesaleProductsListRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PaginatedMinimumProductPackageWithPromotionList>>;
    public generalTradesWholesaleProductsList(requestParameters: GeneralTradesWholesaleProductsListRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const brand = requestParameters.brand;
        const cursor = requestParameters.cursor;
        const displayName = requestParameters.displayName;
        const hasPromotion = requestParameters.hasPromotion;
        const isDefault = requestParameters.isDefault;
        const isNotForSell = requestParameters.isNotForSell;
        const isPin = requestParameters.isPin;
        const isPublic = requestParameters.isPublic;
        const isTemporarilyUnavailable = requestParameters.isTemporarilyUnavailable;
        const name = requestParameters.name;
        const notifyUpdateFromMaster = requestParameters.notifyUpdateFromMaster;
        const pageSize = requestParameters.pageSize;
        const primaryCategory = requestParameters.primaryCategory;
        const primaryCategoryId = requestParameters.primaryCategoryId;
        const product = requestParameters.product;
        const productGroup = requestParameters.productGroup;
        const productGroupNull = requestParameters.productGroupNull;
        const search = requestParameters.search;
        const secondaryCategory = requestParameters.secondaryCategory;
        const subCategory = requestParameters.subCategory;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (brand !== undefined && brand !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>brand, 'brand');
        }
        if (cursor !== undefined && cursor !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>cursor, 'cursor');
        }
        if (displayName !== undefined && displayName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>displayName, 'display_name');
        }
        if (hasPromotion !== undefined && hasPromotion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>hasPromotion, 'has_promotion');
        }
        if (isDefault !== undefined && isDefault !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isDefault, 'is_default');
        }
        if (isNotForSell !== undefined && isNotForSell !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isNotForSell, 'is_not_for_sell');
        }
        if (isPin !== undefined && isPin !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isPin, 'is_pin');
        }
        if (isPublic !== undefined && isPublic !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isPublic, 'is_public');
        }
        if (isTemporarilyUnavailable !== undefined && isTemporarilyUnavailable !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isTemporarilyUnavailable, 'is_temporarily_unavailable');
        }
        if (name !== undefined && name !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>name, 'name');
        }
        if (notifyUpdateFromMaster !== undefined && notifyUpdateFromMaster !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>notifyUpdateFromMaster, 'notify_update_from_master');
        }
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'page_size');
        }
        if (primaryCategory !== undefined && primaryCategory !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>primaryCategory, 'primary_category');
        }
        if (primaryCategoryId !== undefined && primaryCategoryId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>primaryCategoryId, 'primary_category_id');
        }
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'product');
        }
        if (productGroup !== undefined && productGroup !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>productGroup, 'product_group');
        }
        if (productGroupNull !== undefined && productGroupNull !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>productGroupNull, 'product_group_null');
        }
        if (search !== undefined && search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>search, 'search');
        }
        if (secondaryCategory !== undefined && secondaryCategory !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>secondaryCategory, 'secondary_category');
        }
        if (subCategory !== undefined && subCategory !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>subCategory, 'sub_category');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/general-trades/wholesale-products/`;
        return this.httpClient.request<PaginatedMinimumProductPackageWithPromotionList>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generalTradesWholesaleProductsPromotionDetailRetrieve(requestParameters: GeneralTradesWholesaleProductsPromotionDetailRetrieveRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PromotionPolymorphic>;
    public generalTradesWholesaleProductsPromotionDetailRetrieve(requestParameters: GeneralTradesWholesaleProductsPromotionDetailRetrieveRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PromotionPolymorphic>>;
    public generalTradesWholesaleProductsPromotionDetailRetrieve(requestParameters: GeneralTradesWholesaleProductsPromotionDetailRetrieveRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PromotionPolymorphic>>;
    public generalTradesWholesaleProductsPromotionDetailRetrieve(requestParameters: GeneralTradesWholesaleProductsPromotionDetailRetrieveRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling generalTradesWholesaleProductsPromotionDetailRetrieve.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/general-trades/wholesale-products/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/promotion-detail/`;
        return this.httpClient.request<PromotionPolymorphic>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generalTradesWishlistIdsRetrieve(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<WishListID>;
    public generalTradesWishlistIdsRetrieve(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<WishListID>>;
    public generalTradesWishlistIdsRetrieve(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<WishListID>>;
    public generalTradesWishlistIdsRetrieve(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/general-trades/wishlist/ids/`;
        return this.httpClient.request<WishListID>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generalTradesWishlistList(requestParameters: GeneralTradesWishlistListRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PaginatedMinimumProductPackageWithPromotionList>;
    public generalTradesWishlistList(requestParameters: GeneralTradesWishlistListRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PaginatedMinimumProductPackageWithPromotionList>>;
    public generalTradesWishlistList(requestParameters: GeneralTradesWishlistListRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PaginatedMinimumProductPackageWithPromotionList>>;
    public generalTradesWishlistList(requestParameters: GeneralTradesWishlistListRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const brand = requestParameters.brand;
        const cursor = requestParameters.cursor;
        const displayName = requestParameters.displayName;
        const hasPromotion = requestParameters.hasPromotion;
        const isDefault = requestParameters.isDefault;
        const isNotForSell = requestParameters.isNotForSell;
        const isPin = requestParameters.isPin;
        const isPublic = requestParameters.isPublic;
        const isTemporarilyUnavailable = requestParameters.isTemporarilyUnavailable;
        const name = requestParameters.name;
        const notifyUpdateFromMaster = requestParameters.notifyUpdateFromMaster;
        const pageSize = requestParameters.pageSize;
        const primaryCategory = requestParameters.primaryCategory;
        const primaryCategoryId = requestParameters.primaryCategoryId;
        const product = requestParameters.product;
        const productGroup = requestParameters.productGroup;
        const productGroupNull = requestParameters.productGroupNull;
        const search = requestParameters.search;
        const secondaryCategory = requestParameters.secondaryCategory;
        const subCategory = requestParameters.subCategory;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (brand !== undefined && brand !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>brand, 'brand');
        }
        if (cursor !== undefined && cursor !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>cursor, 'cursor');
        }
        if (displayName !== undefined && displayName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>displayName, 'display_name');
        }
        if (hasPromotion !== undefined && hasPromotion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>hasPromotion, 'has_promotion');
        }
        if (isDefault !== undefined && isDefault !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isDefault, 'is_default');
        }
        if (isNotForSell !== undefined && isNotForSell !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isNotForSell, 'is_not_for_sell');
        }
        if (isPin !== undefined && isPin !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isPin, 'is_pin');
        }
        if (isPublic !== undefined && isPublic !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isPublic, 'is_public');
        }
        if (isTemporarilyUnavailable !== undefined && isTemporarilyUnavailable !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>isTemporarilyUnavailable, 'is_temporarily_unavailable');
        }
        if (name !== undefined && name !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>name, 'name');
        }
        if (notifyUpdateFromMaster !== undefined && notifyUpdateFromMaster !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>notifyUpdateFromMaster, 'notify_update_from_master');
        }
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'page_size');
        }
        if (primaryCategory !== undefined && primaryCategory !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>primaryCategory, 'primary_category');
        }
        if (primaryCategoryId !== undefined && primaryCategoryId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>primaryCategoryId, 'primary_category_id');
        }
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'product');
        }
        if (productGroup !== undefined && productGroup !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>productGroup, 'product_group');
        }
        if (productGroupNull !== undefined && productGroupNull !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>productGroupNull, 'product_group_null');
        }
        if (search !== undefined && search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>search, 'search');
        }
        if (secondaryCategory !== undefined && secondaryCategory !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>secondaryCategory, 'secondary_category');
        }
        if (subCategory !== undefined && subCategory !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>subCategory, 'sub_category');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/general-trades/wishlist/`;
        return this.httpClient.request<PaginatedMinimumProductPackageWithPromotionList>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generalTradesWishlistSetWishlistCreate(requestParameters: GeneralTradesWishlistSetWishlistCreateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<WishListID>;
    public generalTradesWishlistSetWishlistCreate(requestParameters: GeneralTradesWishlistSetWishlistCreateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<WishListID>>;
    public generalTradesWishlistSetWishlistCreate(requestParameters: GeneralTradesWishlistSetWishlistCreateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<WishListID>>;
    public generalTradesWishlistSetWishlistCreate(requestParameters: GeneralTradesWishlistSetWishlistCreateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const wishListUpdateRequest = requestParameters.wishListUpdateRequest;
        if (wishListUpdateRequest === null || wishListUpdateRequest === undefined) {
            throw new Error('Required parameter wishListUpdateRequest was null or undefined when calling generalTradesWishlistSetWishlistCreate.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/x-www-form-urlencoded',
            'multipart/form-data'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/general-trades/wishlist/set-wishlist/`;
        return this.httpClient.request<WishListID>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: wishListUpdateRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}
