/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `MORNING` - Morning * `AFTERNOON` - Afternoon * `EVENING` - Evening * `` - None
 */
export type ChosenPickUpTimeEnum = 'MORNING' | 'AFTERNOON' | 'EVENING';

export const ChosenPickUpTimeEnum = {
    Morning: 'MORNING' as ChosenPickUpTimeEnum,
    Afternoon: 'AFTERNOON' as ChosenPickUpTimeEnum,
    Evening: 'EVENING' as ChosenPickUpTimeEnum
};

